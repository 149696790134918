// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import {
    Elements,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInputSite,
    TextInputSite,
} from 'components'

// data
import {
    api_url_main_country,
    api_url_shipping_cost_form_cost_calculation_site,
    api_url_shipping_country,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesShippingCostSite,
} from 'data'

// pages
import {
    MondialRelayWidgetSite,
    PaypalCheckoutSite,
    StripeCheckoutSite,
} from 'pages'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// main
export const PortfolioCheckoutSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    useEffect(() => {
        if (reduxSalesSite.shippingTotal === undefined) {
            setShipping_info({
                ...shipping_info,
                country: '',
            })
        }
    }, [
        reduxSalesSite.shippingTotal,
    ])

    const errorsFields = {
        billing_info: {
            address: '',
            city: '',
            country: '',
            first_name: '',
            last_name: '',
            zip_code: '',
        },
        contact_info: {
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
        },
        shipping_info: {
            address: '',
            city: '',
            country: '',
            zip_code: '',
        },
    }

    const [billing_info, setBilling_info] = useState<any>(reduxSalesSite.billing_info)
    const [contact_info, setContact_info] = useState<any>(reduxSalesSite.contact_info)
    const [errors, setErrors] = useState<any>(errorsFields)
    const [hasError, setHasError] = useState(false)
    const [mainErrors, setMainErrors] = useState<any>()
    const [shipping_info, setShipping_info] = useState<any>(reduxSalesSite.shipping_info)
    const [shippingType, setShippingType] = useState<any>('shipping')
    const [otherShippingCost, setOtherShippingCost] = useState<{
        id: number,
        agent: string,
        cost: number,
        service: string,
    }[] | undefined>()

    const hasMondialRelay = reduxCacheSite.portfolio?.id === 654  // TODO dynamic

    function handleInputChange(event: any, group: any) {
        try {
            if (group === 'billing_info') {
                setBilling_info({
                    ...billing_info,
                    [event.name]: event.value,
                })
                setErrors({
                    ...errors,
                    billing_info: {
                        ...errors.billing_info,
                        [event.name]: '',
                    }
                })
            } else if (group === 'contact_info') {
                setContact_info({
                    ...contact_info,
                    [event.name]: event.value,
                })
                setErrors({
                    ...errors,
                    contact_info: {
                        ...errors.contact_info,
                        [event.name]: '',
                    }
                })
            } else if (group === 'shipping_info') {
                setShipping_info({
                    ...shipping_info,
                    [event.name]: event.value,
                })
                setErrors({
                    ...errors,
                    shipping_info: {
                        ...errors.shipping_info,
                        [event.name]: '',
                    }
                })
                if (event.name === 'country' && event.value && reduxCacheSite.portfolio?.is_shipping_activated) {
                    getShippingCost(event.value.id)
                }
            }
            setHasError(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PortfolioCheckoutSite',
                'handleInputChange',
            ))
        }
    }

    function getShippingCost(countryId: number, shippingService?: number) {
        try {
            const formData: any = new FormData()
            formData.append('country', countryId)
            formData.append('service', reduxCacheSite.portfolio?.shipping_services?.join(','))
            formData.append('sales_lines', JSON.stringify(reduxSalesSite.salesLines))
            if (reduxModalSite.currencyId) {
                formData.append('currency_id', reduxModalSite.currencyId)
            }
            const postUrl = getApiUrlSite(api_url_shipping_cost_form_cost_calculation_site, reduxModalSite)
            axios({
                data: formData,
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const costIndex = shippingService ? response.data.costs.findIndex((val: any) => val.service_id === shippingService) : 0
                    if (shippingService) {
                        dispatch(reduxSalesShippingCostSite(response.data.costs?.[costIndex]?.cost, response.data.costs?.[costIndex]?.id))
                    } else {
                        dispatch(reduxSalesShippingCostSite(response.data.costs.filter((obj: any) => obj.agent !== 'Mondial Relay')?.[costIndex]?.cost, response.data.costs.filter((obj: any) => obj.agent !== 'Mondial Relay')?.[costIndex]?.id))
                    }
                    if (!hasMondialRelay) {
                        setOtherShippingCost(response.data.costs)
                    }
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: postUrl,
                        component: 'PortfolioCheckoutSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getShippingCost',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PortfolioCheckoutSite',
                'getShippingCost',
            ))
        }
    }

    const noShiping = reduxCacheSite.portfolio?.id === 367  // TODO dynamic

    return (
        <div className={`portfolio-checkout ${deviceType}`}>
            <div className={`pc-wrap ${deviceType}`}>
                <div className={`pc-contact-info ${deviceType}`}>
                    <p className={`pc-title ${deviceType}`}>{reduxText[7474]}</p>
                    <TextInputSite
                        error={errors.contact_info.first_name}
                        label={reduxText[7475]}
                        name='first_name'
                        onChange={(e) => handleInputChange(e, 'contact_info')}
                        stylesLabel={{ color: '#343434' }}
                        value={contact_info.first_name || ''}
                    />
                    <TextInputSite
                        error={errors.contact_info.last_name}
                        label={reduxText[7476]}
                        name='last_name'
                        onChange={(e) => handleInputChange(e, 'contact_info')}
                        stylesLabel={{ color: '#343434' }}
                        value={contact_info.last_name || ''}
                    />
                    <TextInputSite
                        error={errors.contact_info.email}
                        label={reduxText[7477]}
                        name='email'
                        onChange={(e) => handleInputChange(e, 'contact_info')}
                        stylesLabel={{ color: '#343434' }}
                        value={contact_info.email || ''}
                    />
                    <TextInputSite
                        error={errors.contact_info.phone}
                        label={reduxText[7478]}
                        name='phone'
                        onChange={(e) => handleInputChange(e, 'contact_info')}
                        stylesLabel={{ color: '#343434' }}
                        value={contact_info.phone || ''}
                    />
                </div>
                <div className={`pc-shipping-info ${deviceType}`}>
                    <p className={`pc-title ${deviceType}`}>{(reduxCacheSite.portfolio?.stripe_capture_method_is_manual || noShiping) ? reduxText[7485] : reduxText[7479]}</p>
                    {hasMondialRelay && (
                        <div className={`pc-shipping-type ${deviceType}`}>
                            <div>
                                <input
                                    checked={shippingType === 'shipping'}
                                    name='payment_method'
                                    onChange={(e) => {
                                        setShippingType(e.target.value)
                                        setShipping_info({
                                            address: '',
                                            city: '',
                                            country: '',
                                            relay_point_id: '',
                                            relay_point_name: '',
                                            zip_code: '',
                                        })
                                    }}
                                    type='radio'
                                    value='shipping'
                                />
                                <label>Livraison à domicile</label>
                            </div>
                            <div>
                                <input
                                    checked={shippingType === 'pickup'}
                                    name='payment_method'
                                    onChange={(e) => {
                                        setShippingType(e.target.value)
                                        setShipping_info({
                                            address: '',
                                            city: '',
                                            country: '',
                                            relay_point_id: '',
                                            relay_point_name: '',
                                            zip_code: '',
                                        })
                                    }}
                                    type='radio'
                                    value='pickup'
                                />
                                <label>Livraison en point relais</label>
                            </div>
                        </div>
                    )}
                    {shippingType === 'shipping' && (
                        <>
                            <TextInputSite
                                error={errors.shipping_info.address}
                                label={reduxText[7480]}
                                name='address'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.address || ''}
                            />
                            <TextInputSite
                                error={errors.shipping_info.zip_code}
                                label={reduxText[7482]}
                                name='zip_code'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.zip_code || ''}
                            />
                            <TextInputSite
                                error={errors.shipping_info.city}
                                label={reduxText[7483]}
                                name='city'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.city || ''}
                            />
                            {/* TODO dynamic */}
                            <SelectListInputSite
                                apiUrl={getApiUrlSite(reduxCacheSite.portfolio?.is_shipping_activated ? `${api_url_shipping_country}?portfolio=${reduxCacheSite.portfolio.id}${reduxCacheSite.portfolio.id === 450 ? '&eshop_name=true' : ''}` : api_url_main_country, reduxModalSite)}
                                error={errors.shipping_info.country}
                                label={reduxText[7484]}
                                stylesLabel={{ color: '#343434' }}
                                name='country'
                                onChange={(e: any) => handleInputChange(e, 'shipping_info')}
                                value={shipping_info.country || ''}
                            />
                        </>
                    )}
                    {shippingType === 'pickup' && (
                        <>
                            <div className={`pc-mondial-relay-widget ${deviceType}`}>
                                <MondialRelayWidgetSite
                                    getShippingCost={getShippingCost}
                                    shipping_info={shipping_info}
                                    setShipping_info={setShipping_info}
                                />
                            </div>
                            <TextInputSite
                                disabled
                                error={errors.shipping_info.relay_point_name}
                                label='Point relais'
                                name='relay_point_name'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.relay_point_name || ''}
                            />
                            <TextInputSite
                                disabled
                                error={errors.shipping_info.relay_point_id}
                                label='N° Point relais'
                                name='relay_point_id'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.relay_point_id || ''}
                            />
                            <TextInputSite
                                disabled
                                error={errors.shipping_info.address}
                                label={reduxText[7480]}
                                name='address'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.address || ''}
                            />
                            <TextInputSite
                                disabled
                                error={errors.shipping_info.zip_code}
                                label={reduxText[7482]}
                                name='zip_code'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.zip_code || ''}
                            />
                            <TextInputSite
                                disabled
                                error={errors.shipping_info.city}
                                label={reduxText[7483]}
                                name='city'
                                onChange={(e) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.city || ''}
                            />
                            {/* TODO dynamic */}
                            <SelectListInputSite
                                apiUrl={getApiUrlSite(reduxCacheSite.portfolio?.is_shipping_activated ? `${api_url_shipping_country}?portfolio=${reduxCacheSite.portfolio.id}${reduxCacheSite.portfolio.id === 450 ? '&eshop_name=true' : ''}` : api_url_main_country, reduxModalSite)}
                                disabled
                                error={errors.shipping_info.country}
                                label={reduxText[7484]}
                                name='country'
                                onChange={(e: any) => handleInputChange(e, 'shipping_info')}
                                stylesLabel={{ color: '#343434' }}
                                value={shipping_info.country || ''}
                            />
                        </>
                    )}
                </div>
                {reduxCacheSite.portfolio?.is_shipping_activated && (
                    <div className={`pc-shipping-cost ${deviceType}`}>
                        <p className={`pc-shipping-cost-text ${deviceType}`}>{reduxText[8518]} {reduxSalesSite.shippingTotal && Number(reduxSalesSite.shippingTotal.toFixed(2))} {reduxSalesSite.shippingTotal !== undefined && reduxSalesSite.currency_symbol}</p>
                        {otherShippingCost?.length! > 1 && (
                            <div className={`pc-other-costs ${deviceType}`}>
                                {otherShippingCost?.map(val => (
                                    <div
                                        key={val.id}
                                        className={`pc-other-costs-item${reduxSalesSite.shippingService === val.id ? ' active' : ''} ${deviceType}`}
                                        onClick={() => dispatch(reduxSalesShippingCostSite(val.cost, val.id))}
                                    >
                                        <p className={`pc-other-costs-item-service ${deviceType}`}>{val.agent} - {val.service}</p>
                                        <p className={`pc-other-costs-item-cost ${deviceType}`}>{Number(val.cost.toFixed(2))} {reduxSalesSite.currency_symbol}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {!noShiping && !reduxCacheSite.portfolio?.stripe_capture_method_is_manual && (
                    <div className={`pc-billing-info ${deviceType}`}>
                        <p className={`pc-title ${deviceType}`}>{reduxText[7485]}</p>
                        <div className={`pc-checkbox-wrap ${deviceType}`}>
                            <input
                                checked={billing_info.same_as_shipping || ''}
                                name='same_as_shipping'
                                onChange={(event) => handleInputChange({ name: event.target.name, value: event.target.checked }, 'billing_info')}
                                type='checkbox'
                            />
                            <p className={`pc-checkbox ${deviceType}`}>{reduxText[7486]}</p>
                        </div>
                        {!billing_info.same_as_shipping && (
                            <div>
                                <TextInputSite
                                    error={errors.billing_info.first_name}
                                    label={reduxText[7475]}
                                    stylesLabel={{ color: '#343434' }}
                                    name='first_name'
                                    onChange={(e) => handleInputChange(e, 'billing_info')}
                                    value={billing_info.first_name || ''}
                                />
                                <TextInputSite
                                    error={errors.billing_info.last_name}
                                    label={reduxText[7476]}
                                    stylesLabel={{ color: '#343434' }}
                                    name='last_name'
                                    onChange={(e) => handleInputChange(e, 'billing_info')}
                                    value={billing_info.last_name || ''}
                                />
                                <TextInputSite
                                    error={errors.billing_info.address}
                                    label={reduxText[7480]}
                                    stylesLabel={{ color: '#343434' }}
                                    name='address'
                                    onChange={(e) => handleInputChange(e, 'billing_info')}
                                    value={billing_info.address || ''}
                                />
                                <TextInputSite
                                    error={errors.billing_info.zip_code}
                                    label={reduxText[7482]}
                                    stylesLabel={{ color: '#343434' }}
                                    name='zip_code'
                                    onChange={(e) => handleInputChange(e, 'billing_info')}
                                    value={billing_info.zip_code || ''}
                                />
                                <TextInputSite
                                    error={errors.billing_info.city}
                                    label={reduxText[7483]}
                                    stylesLabel={{ color: '#343434' }}
                                    name='city'
                                    onChange={(e) => handleInputChange(e, 'billing_info')}
                                    value={billing_info.city || ''}
                                />
                                <SelectListInputSite
                                    apiUrl={getApiUrlSite(api_url_main_country, reduxModalSite)}
                                    error={errors.billing_info.country}
                                    label={reduxText[7484]}
                                    name='country'
                                    onChange={(e: any) => handleInputChange(e, 'billing_info')}
                                    stylesLabel={{ color: '#343434' }}
                                    value={billing_info.country || ''}
                                />
                            </div>
                        )}
                    </div>
                )}
                {hasError && (
                    <span className={`pc-form-error ${deviceType}`}>{reduxText[8517]}</span>
                )}
                {mainErrors?.shipping_cost && (
                    <span className='pc-form-error'>{mainErrors.shipping_cost}</span>
                )}
                {reduxCacheSite.portfolio?.token_paypal_client_id
                    ? (
                        <PayPalScriptProvider
                            options={{
                                'clientId': process.env.NODE_ENV === 'development' ? 'Ace5EvaBA0nxm5yVdRupa3VaRj3dwMyjgbYCZvhMzkhUJ-aqW-JdjKs_rs--e0yAhXTvwejrooQYtT5C' : reduxCacheSite.portfolio.token_paypal_client_id,
                                'components': 'buttons',
                                'currency': reduxCacheSite.portfolio.currency?.iso_code,
                            }}
                        >
                            <PaypalCheckoutSite
                                billing_info={billing_info}
                                contact_info={contact_info}
                                setErrors={setErrors}
                                setHasError={setHasError}
                                setMainErrors={setMainErrors}
                                shipping_info={shipping_info}
                            />
                        </PayPalScriptProvider>
                    ) : (
                        <Elements stripe={reduxModalSite.stripePromise}>
                            <StripeCheckoutSite
                                billing_info={billing_info}
                                contact_info={contact_info}
                                setErrors={setErrors}
                                setHasError={setHasError}
                                setMainErrors={setMainErrors}
                                shipping_info={shipping_info}
                            />
                        </Elements>
                    )}
            </div>
        </div>
    )
}
